import { Link } from "react-router-dom";
import React, { useState } from "react";
import { Button, Input } from 'antd';
import Amplify, { Auth, Cache } from 'aws-amplify';
import 'react-native-get-random-values';
import axios from "axios";

Amplify.configure({
    Auth: {

        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

        // REQUIRED - Amazon Cognito Region
        region: 'us-east-1',

        // OPTIONAL - Amazon Cognito Federated Identity Pool Region
        // Required only if it's different from Amazon Cognito Region
        // identityPoolRegion: 'us-east-1',

        // OPTIONAL - Amazon Cognito User Pool ID
        // userPoolId: 'us-east-1_I3Phs6nNp',
        userPoolId: 'us-east-1_fn1hffO3E',

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        // userPoolWebClientId: '3l7cgq8p9bis77mkdhg8qo3a5q',
        userPoolWebClientId: '5t3avnbg4lf9bm765q7vg0j7t7',
    }
});

const currentConfig = Auth.configure();

console.log("currentConfig: ", currentConfig);

function Login({backendUrl}) {

  const [cognitoUser, setCognitoUser] = useState();
  const [email, setEmail] = useState();
  const [code, setCode] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [status, setStatus] = useState("SignIn");

  function getRandomString(bytes) {
    const randomValues = new Uint8Array(bytes);
    crypto.getRandomValues(randomValues);
    return Array.from(randomValues).map(intToHex).join('');
  }

  function intToHex(nr) {
    return nr.toString(16).padStart(2, '0');
  }

  async function signUp() {
      try {
          console.log("SignUn email: ", email);
          const password = getRandomString(30);
          console.log("password: ", password);
          // TODO: validate email
          await Auth.signUp({
              username: email,
              password: "algopassmasld",
              attributes: {
                  name: "Damu",
              }
          });
          const user = await Auth.signIn(email);
          console.log(user);
          setCognitoUser(user);
          setStatus("AnswerCustomChallenge");
      } catch (error) {
          console.log('error signing up:', error);
          setErrorMessage(error.message);
      }
  }

  async function signIn() {
      try {
          console.log("SignIn email: ", email);
          // TODO: validate email
          const user = await Auth.signIn(email);
          console.log("user: ", user);
          setCognitoUser(user);
          setStatus("AnswerCustomChallenge");
      } catch (error) {
          console.log('error signing in', error);
          setErrorMessage(error.message);
      }
  }

  async function answerCustomChallenge() {
      try {
          console.log("AnswerCustomChallenge code: ", code);
          console.log("Auth: ", Auth);
          const user = await Auth.sendCustomChallengeAnswer(cognitoUser, code, {});
          console.log("user: ", user);
          const authenticated = await isAuthenticated();
          console.log("authenticated: ", authenticated);
          if (authenticated === true) {
            setCognitoUser(user);
            setStatus("LoggedIn");
          } else {
            setErrorMessage("Wrong code!");
          }
      } catch (error) {
          console.log('error signing in', error);
      }
  }

  async function isAuthenticated() {
    try {
      await Auth.currentSession();
      console.log("isAuthenticated: ", true)
      return true;
    } catch {
      console.log("isAuthenticated: ", false)
      return false;
    }
  }

  async function signOut() {
      try {
          await Auth.signOut();
          setCognitoUser(null);
          setStatus("SignIn");
      } catch (error) {
          console.log('error signing out: ', error);
      }
  }

  async function getUserDetails() {
    /*
    if (!this.cognitoUser) {
      this.cognitoUser = await Auth.currentAuthenticatedUser();
    }
    */
    const attributes = await Auth.userAttributes(cognitoUser);
    console.log("attributes: ", attributes);
  }

  async function getToken() {
    // const federatedInfo = await Cache.getItem('federatedInfo');
    // const { token } = federatedInfo;
    const currentSession = await Auth.currentSession()
    console.log("currentSession: ", currentSession);
    console.log("jwt: ", currentSession.accessToken.jwtToken);
    // const currentCredentials = await Auth.currentCredentials()
    // console.log("currentCredentials: ", currentCredentials);

    const email = "damianmarti@gmail.com";
    const response = await axios.get("https://o0fgc808k9.execute-api.us-east-1.amazonaws.com/users/" + email + "/stats", { params: { offset: 0, limit: 50}, headers: {
    'Authorization': `Bearer ${currentSession.accessToken.jwtToken}`
  } });
    console.log("response: ", response);
  }

  return (
    <>
      <div className="ranking-title">
        <div className="back">
          <i className="fa fa-arrow-left"></i>
          <Link to="/">All Rankings</Link>
        </div>
        <h1>Login</h1>
        <Button onClick={() => {
          getToken();
        }}>GetToken</Button>
        <div className="subtitle">
          { status === "SignUp" ? (
            <p>
              Existing user?
              <Button
                onClick={() => {
                  setStatus("SignIn");
                }}
              >Login</Button>
            </p>
          ) : (
            <p>
              New?
              <Button
                onClick={() => {
                  setStatus("SignUp");
                }}
              >SignUp</Button>
            </p>
          )}
        </div>
      </div>
      { status === "SignIn" && (
        <div className="login-form">
          <Input
            placeholder="email"
            onChange={newValue => {
              setEmail(newValue.target.value);
            }}
          />
          <Button onClick={signIn}>Send Login Code</Button>
          {errorMessage}
        </div>
      )}
      { status === "AnswerCustomChallenge" && (
        <div className="login-code">
          <Input
            placeholder="code"
            onChange={newValue => {
              setCode(newValue.target.value);
            }}
          />
          <Button onClick={answerCustomChallenge}>Login</Button>
          {errorMessage}
        </div>
      )}
      { status === "LoggedIn" && (
        <div className="logged-in">
          <p>
            Logged in as {cognitoUser.username}!
            <Button onClick={signOut}>Log out</Button>
          </p>
        </div>
      )}
      { status === "SignUp" && (
        <div className="sign-up-form">
          <Input
            placeholder="email"
            onChange={newValue => {
              setEmail(newValue.target.value);
            }}
          />
          <Button onClick={signUp}>Send Sign Up Code</Button>
          {errorMessage}
        </div>
      )}
    </>
  );
}

export default Login;
