import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { DatePicker } from 'antd';
import axios from "axios";

function RankingByCoins({backendUrl}) {

  const [ranking, setRanking] = useState();

  let toDate = new Date().toISOString().slice(0, 10);

  let lastMondayDate = new Date();
  lastMondayDate.setDate(lastMondayDate.getDate() - (lastMondayDate.getDay() + 6) % 7);

  let fromDate = lastMondayDate.toISOString().slice(0, 10);

  useEffect(() => {
    const updateRanking = async () => {
      try {
        const response = await axios.get(backendUrl + "ranking/coins/desc", { params: { limit: 50 }});
        setRanking(response.data.results);
        console.log(response);
      } catch (error) {
        console.error(error);
      }
    };
    updateRanking();
  }, [backendUrl]);

  return (
    <>
      <div className="ranking-title">
        <div className="back">
          <i className="fa fa-arrow-left"></i>
          <Link to="/">All Rankings</Link>
        </div>
        <h1>Ranking by Coins Grabbed</h1>
        <div className="subtitle">
          Not appearing in ranking? Please add your twitter account to participate
        </div>
      </div>
      <div className="ranking-data">
        <h2>From: <span>{fromDate}</span></h2>
        <h2>To: <span>{toDate}</span></h2>
      </div>
        <div className="position-table">
          <table>
              <tr>
                <th>#</th>
                <th>Twitter</th>
                <th>Team</th>
                <th>Coins Grabbed</th>
              </tr>
              {ranking && ranking.length > 0 && ranking.map((user, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{user.twitter}</td>
                  <td>{user.user}</td>
                  <td>{user.coins}</td>
                </tr>
              ))}
        </table>
      </div>
    </>
  );
}

export default RankingByCoins;
