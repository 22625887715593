import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";

function RankingByCountry({backendUrl}) {

  const [ranking, setRanking] = useState();
  const country = useParams().country;

  // TODO: move
  const countries = {
    QAT: "Qatar",
    ECU: "Ecuador",
    FRA: "France",
    AUS: "Australia",
    DEU: "Germany",
    ESP: "Spain",
    URY: "Uruguay ",
    KOR: "Korea",
    ENG: "England",
    USA: "United States",
    ARG: "Argentina",
    MEX: "Mexico",
    HRV: "Croatia",
    IRN: "Iran",
    BRA: "Brazil",
    CHE: "Switzerland",
    SEN: "Senegal",
    NLD: "Netherlands",
    WAL: "Wales",
    CAN: "Canada",
    SAU: "Saudi Arabia",
    POL: "Poland",
    TUN: "Tunisia",
    DEN: "Denmark",
    JPN: "Japan",
    CRI: "Costa Rica",
    MAR: "Morocco",
    BEL: "Belgium",
    SER: "Serbia",
    CMR: "Cameroon",
    PRT: "Portugal",
    GHA: "Ghana"
  };

  useEffect(() => {
    const updateRanking = async () => {
      try {
        const response = await axios.get(backendUrl + "ranking/country/" + country);
        setRanking(response.data.results);
        console.log(response);
      } catch (error) {
        console.error(error);
      }
    };
    updateRanking();
  }, [backendUrl, country]);

  return (
    <>
      <div className="ranking-title">
        <div className="back">
          <i className="fa fa-arrow-left"></i>
          <Link to="/countries">Earth Cup Ranking</Link>
        </div>
        <h1>Ranking {countries[country]}</h1>
        <div className="subtitle">
          Not appearing in ranking? Please add your twitter account to participate
        </div>
      </div>
      <div className="position-table">
        <table>
          <tr>
            <th>#</th>
            <th>Twitter</th>
            <th>Team</th>
            <th class="number">Matches</th>
            <th class="number">Score</th>
          </tr>
          {ranking && ranking.length > 0 && ranking.map((user, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>{user.twitter}</td>
              <td>{user.user}</td>
              <td class="number">{user.matches}</td>
              <td class="number">{user.base_score}</td>
            </tr>
          ))}
        </table>
      </div>
    </>
  );
}

export default RankingByCountry;
