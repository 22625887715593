import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";

function RankingByReferralScore({backendUrl}) {

  const [ranking, setRanking] = useState();

  useEffect(() => {
    const updateRanking = async () => {
      try {
        const response = await axios.get(backendUrl + "ranking_referral/score", { params: { limit: 50 }});
        setRanking(response.data.results);
        console.log(response);
      } catch (error) {
        console.error(error);
      }
    };
    updateRanking();
  }, [backendUrl]);

  return (
    <>
      <div className="ranking-title">
        <div className="back">
          <i className="fa fa-arrow-left"></i>
          <Link to="/">All Rankings</Link>
        </div>
        <h1>Ranking by Referral Score</h1>
      </div>
        <div className="position-table">
          <table>
              <tr>
                <th>#</th>
                <th>Twitter</th>
                <th>Team</th>
                <th>Referral Score</th>
              </tr>
              {ranking && ranking.length > 0 && ranking.map((user, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{user.twitter}</td>
                  <td>{user.user}</td>
                  <td>{user.referral_score}</td>
                </tr>
              ))}
        </table>
      </div>
    </>
  );
}

export default RankingByReferralScore;
