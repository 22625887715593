import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { DatePicker, Table } from 'antd';
import axios from "axios";
import moment from "moment";

const { RangePicker, RangePickerProps } = DatePicker;

function Ranking({backendUrl}) {

  const [ranking, setRanking] = useState();
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });
  const [sortField, setSortField] = useState("coins");
  const [sortOrder, setSortOrder] = useState("desc");
  const [limit, setLimit] = useState(50);
  const [offset, setOffset] = useState(0);

  let toDateDefault = new Date().toISOString().slice(0, 10);

  let lastMondayDate = new Date();
  lastMondayDate.setDate(lastMondayDate.getDate() - (lastMondayDate.getDay() + 6) % 7);

  let fromDateDefault = lastMondayDate.toISOString().slice(0, 10);

  const [fromDate, setFromDate] = useState(fromDateDefault);
  const [toDate, setToDate] = useState(toDateDefault);

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      sorter: false,
      render: (text, record, index) => {
        return index + 1 + (pagination.current - 1) * pagination.pageSize;
      },
    },
    {
      title: 'Twitter',
      dataIndex: 'twitter',
      sorter: true,
    },
    {
      title: 'Team',
      dataIndex: 'user',
      sorter: true,
    },
    {
      title: 'Coins',
      dataIndex: 'coins',
      sorter: true,
      defaultSortOrder: "descend",
    },
    {
      title: 'Score',
      dataIndex: 'max_score',
      sorter: true,
    },
    {
      title: 'Result',
      dataIndex: 'max_full_score',
      render: (text, record, index) =>
        parseInt(text.slice(text.length-4, text.length-2)) + " - " + parseInt(text.slice(text.length-2, text.length)),
    },
    {
      title: 'Total Matches',
      dataIndex: 'total_matches',
      sorter: true,
    },
    {
      title: 'Total Won',
      dataIndex: 'total_won',
      sorter: true,
    },
    {
      title: 'Total Draw',
      dataIndex: 'total_draw',
      sorter: true,
    },
    {
      title: 'Total Lost',
      dataIndex: 'total_lost',
      sorter: true,
    },
  ];

  useEffect(() => {
    const updateRanking = async () => {
      try {
        setLoading(true);
        const response = await axios.get(backendUrl + "ranking/" + sortField + "/" + sortOrder, { params: { limit: limit, offset: offset, from: fromDate, to: toDate }});
        console.log(response);
        setRanking(response.data.results);
        setLoading(false)
        setPagination({
          ...pagination,
          total: response.data.total,
        });
      } catch (error) {
        console.error(error);
      }
    };
    updateRanking();
  }, [backendUrl, fromDate, toDate, sortField, sortOrder, limit, offset]);

  const handleTableChange = (newPagination, filters, sorter) => {
    console.log("Sorter: ", sorter);
    console.log("newPagination: ", newPagination);
    setSortField(sorter.field);
    if (sorter.order === "descend") {
      setSortOrder('desc');
    } else {
      setSortOrder('asc');
    }
    if (newPagination.pageSize) {
      setLimit(newPagination.pageSize);
    }
    const offset = (newPagination.current - 1) * newPagination.pageSize;
    setOffset(offset);
    setPagination({ current: newPagination.current, pageSize: newPagination.pageSize });
  };

  const onChangeDates: RangePickerProps['onChange'] = (dates, dateStrings) => {
    console.log(dates, dateStrings);
    setFromDate(dateStrings[0]);
    setToDate(dateStrings[1]);
  };

  return (
    <>
      <div className="ranking-title">
        <div className="back">
          <i className="fa fa-arrow-left"></i>
          <Link to="/">All Rankings</Link>
        </div>
        <h1>Rankings</h1>
      </div>
      <div className="ranking-data">
        <h2>From/to:</h2>
        <RangePicker defaultValue={[moment(fromDate), moment(toDate)]} onChange={onChangeDates} />
      </div>
      <div className="position-table">
        <Table
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={ranking}
          pagination={pagination}
          loading={loading}
          onChange={handleTableChange}
          sortDirections={['ascend', 'descend', 'ascend']}
        />
      </div>
    </>
  );
}

export default Ranking;
