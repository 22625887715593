import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { default as Home } from "./views/Home";
import { default as RankingByCoins } from "./views/RankingByCoins";
import { default as RankingByMatchesWon } from "./views/RankingByMatchesWon";
import { default as GlobalRankingByScore } from "./views/GlobalRankingByScore";
import { default as Ranking } from "./views/Ranking";
import { default as Login } from "./views/Login";
import { default as RankingByStadium } from "./views/RankingByStadium";
import { default as MyStats } from "./views/MyStats";
import { default as RankingByScore } from "./views/RankingByScore";
import { default as RankingByReferralScore } from "./views/RankingByReferralScore";
import { default as RankingCountries } from "./views/RankingCountries";
import { default as RankingByCountry } from "./views/RankingByCountry";

// const backendUrl = "https://o0fgc808k9.execute-api.us-east-1.amazonaws.com/";
const backendUrl = "https://y0e8xxplkh.execute-api.us-east-1.amazonaws.com/";

function App() {
  return (
    <div className="main">
      <div className="container">
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/coins" element={<RankingByCoins backendUrl={backendUrl} />} />
            <Route exact path="/matches" element={<RankingByMatchesWon backendUrl={backendUrl} />} />
            <Route path="/matches/:group" element={<RankingByMatchesWon backendUrl={backendUrl} />} />
            <Route exact path="/global" element={<GlobalRankingByScore backendUrl={backendUrl} />} />
            <Route exact path="/ranking" element={<Ranking backendUrl={backendUrl} />} />
            <Route exact path="/rankingByScore" element={<RankingByScore backendUrl={backendUrl} />} />
            <Route path="/rankingByScore/:from/:to" element={<RankingByScore backendUrl={backendUrl} />} />
            <Route exact path="/best20scores" element={<RankingByScore backendUrl={backendUrl} />} />
            <Route path="/best20scores/:from/:to" element={<RankingByScore backendUrl={backendUrl} />} />
            <Route exact path="/login" element={<Login />} />
            <Route path="/rankingByStadium/:stadium/:level" element={<RankingByStadium backendUrl={backendUrl} />} />
            <Route path="/myStats/:email" element={<MyStats backendUrl={backendUrl} />} />
            <Route exact path="/referrals" element={<RankingByReferralScore backendUrl={backendUrl} />} />
            <Route exact path="/countries" element={<RankingCountries backendUrl={backendUrl} />} />
            <Route path="/countries/:country" element={<RankingByCountry backendUrl={backendUrl} />} />
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
