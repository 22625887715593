import { Link, useParams, useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Table } from 'antd';
import axios from "axios";

function RankingByScore({ backendUrl }) {

  let fromDate = useParams().from;
  let toDate = useParams().to;
  let [searchParams, setSearchParams] = useSearchParams();

  if (!fromDate) {
    let lastTuesdayDate = new Date();
    lastTuesdayDate.setUTCDate(lastTuesdayDate.getUTCDate() - (lastTuesdayDate.getUTCDay() + 5) % 7);
    fromDate = lastTuesdayDate.toISOString().slice(0, 10);
  }

  if (!toDate) {
    toDate = new Date();
    const toDay = toDate.getUTCDay();
    if (toDay !== 4) {
      // Tuesday or Wednesday -> next Thursday
      if (toDay === 2 || toDay === 3) {
        let nextThursdayDate = new Date();
        nextThursdayDate.setUTCDate(nextThursdayDate.getUTCDate() + 4 - toDay);
        toDate = nextThursdayDate.toISOString().slice(0, 10);
      } else {
        let lastThursdayDate = new Date();
        lastThursdayDate.setUTCDate(lastThursdayDate.getUTCDate() - (lastThursdayDate.getUTCDay() + 3) % 7);
        toDate = lastThursdayDate.toISOString().slice(0, 10);
      }
    } else {
      toDate = toDate.toISOString().slice(0, 10);
    }
  }

  const [loading, setLoading] = useState(false);
  const [ranking, setRanking] = useState();
  const [userRanking, setUserRanking] = useState();
  const [limit, setLimit] = useState(50);
  const [offset, setOffset] = useState(0);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      render: (text, record, index) => {
        return index + 1 + (pagination.current - 1) * pagination.pageSize;
      },
    },
    {
      title: 'Twitter',
      dataIndex: 'twitter',
    },
    {
      title: 'Team',
      dataIndex: 'user',
    },
    {
      title: 'Score',
      dataIndex: 'sum_score',
    },
  ];

  useEffect(() => {
    const updateRanking = async () => {
      try {
        setLoading(true);
        const response = await axios.get(backendUrl + "ranking/score", { params: { limit: limit, offset: offset, from: fromDate, to: toDate } });
        setRanking(response.data.results);
        setPagination({
          ...pagination,
          total: response.data.total,
        });
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    updateRanking();
  }, [backendUrl, fromDate, toDate, searchParams, offset]);

  useEffect(() => {
    const updateUserRanking = async () => {
      const userEmail = searchParams.get("user");
      if (userEmail) {
        try {
          const response = await axios.get(backendUrl + "ranking/score", { params: { from: fromDate, to: toDate, email: userEmail } });
          if (response.data.results.length > 0) {
            setUserRanking(response.data.results[0]);
          }
        } catch (error) {
          console.error(error);
        }
      }
    };
    updateUserRanking();
  }, [backendUrl, fromDate, toDate, searchParams]);

  const handleTableChange = (newPagination, filters, sorter) => {
    console.log("newPagination: ", newPagination);
    const offset = (newPagination.current - 1) * newPagination.pageSize;
    setOffset(offset);
    setPagination({ current: newPagination.current, pageSize: newPagination.pageSize });
  };

  return (
    <>
      <div className="ranking-title">
        <div className="back">
          <i className="fa fa-arrow-left"></i>
          <Link to="/">All Rankings</Link>
        </div>
        <h1>
          Ranking by Score
        </h1>
        <div className="subtitle">
          Not appearing in ranking? Please add your twitter account to participate
        </div>
      </div>
      {userRanking && (
        <div style={{ padding: 20 }}>
          <h1>Team: <span>{userRanking.user}</span><span style={{ marginLeft: 20 }}>Score:</span><span>{userRanking.score}</span></h1>
        </div>
      )}
      <div className="ranking-data">
        <h2>From: <span>{fromDate}</span></h2>
        <h2>To: <span>{toDate}</span></h2>
      </div>
      <div className="position-table">
        <Table
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={ranking}
          pagination={pagination}
          loading={loading}
          onChange={handleTableChange}
        />
      </div>
    </>
  );
}

export default RankingByScore;
