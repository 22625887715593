import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Table } from 'antd';
import axios from "axios";
import moment from "moment";

function MyStats({backendUrl}) {

  const [ranking, setRanking] = useState();
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 50,
  });
  const [offset, setOffset] = useState(0);

  const stadiums = ["Fulbo Stadium", "El Barrio Futbol Club", "Maximum Security FC"]; //, "Río de Jamaica Arena", "Berlin Wall Cup 89"];

  const columns = [
    {
      title: '#',
      dataIndex: 'matches',
      sorter: false,
      render: (text, record, index) =>
        text + 1,
    },
    {
      title: 'Stadium',
      dataIndex: 'stadium',
      sorter: false,
      render: (text, record, index) =>
        stadiums[text],
    },
    {
      title: 'Level',
      dataIndex: 'level',
      sorter: false,
    },
    {
      title: 'Score',
      dataIndex: 'score',
      sorter: false,
    },
    {
      title: 'Coins',
      dataIndex: 'coins_grabbed',
      sorter: false,
    },
    {
      title: 'Result',
      dataIndex: 'result',
      render: (text, record, index) =>
        record.score_team2 + " - " + record.score_team1,
    },
    {
      title: 'Date',
      dataIndex: 'timestamp',
      sorter: false,
      render: (text, record, index) =>
        moment(new Date(text)).format("YYYY-MM-DD HH:mm:ss"),
    },
  ];

  const email = useParams().email;

  useEffect(() => {
    const updateRanking = async () => {
      try {
        setLoading(true);
        const response = await axios.get(backendUrl + "users/" + email + "/stats", { params: { offset: offset, limit: 50 }});
        console.log(response);
        setRanking(response.data.results);
        setPagination({
          ...pagination,
          total: response.data.total,
        });
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    updateRanking();
  }, [backendUrl, email, offset]);

  const handleTableChange = (newPagination, filters, sorter) => {
    console.log("newPagination: ", newPagination);
    const offset = (newPagination.current - 1) * newPagination.pageSize;
    setOffset(offset);
    setPagination({ current: newPagination.current, pageSize: newPagination.pageSize });
  };

  return (
    <>
      <div className="ranking-title">
        <div className="back">
          <i className="fa fa-arrow-left"></i>
          <Link to="/">All Rankings</Link>
        </div>
        <h1>My Stats</h1>
      </div>
      <div className="position-table">
        <Table
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={ranking}
          loading={loading}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </div>
    </>
  );
}

export default MyStats;
