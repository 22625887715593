import { Link } from "react-router-dom";

function Home() {
  return (
    <>
      <div className="ranking">
        <h1>Global Rankings</h1>
      </div>
      <div className="container_cards">
        <div className="flex">
          <div className="card">
            <Link to="/coins">
              <div>
                <img src="images/coins.png" alt="Monedas" />
              </div>
              <div>
                <h2>Coins Grabbed</h2>
              </div>
            </Link>
          </div>
          <div className="card">
            <Link to="/rankingByScore">
              <div>
                <img src="images/wins.png" alt="Monedas" />
              </div>
              <div>
                <h2>Ranking</h2>
              </div>
            </Link>
          </div>
          <div className="card">
            <Link to="/global">
              <div>
                <img src="images/scorer.png" alt="Monedas" />
              </div>
              <div>
                <h2>Top Scores</h2>
              </div>
            </Link>
          </div>
        </div>
        <p className="final_phrase">🏆 Stay tuned for weekly awards! 🏆</p>
      </div>
    </>
  );
}

export default Home;
