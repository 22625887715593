import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";

function RankingCountries({backendUrl}) {

  const [ranking, setRanking] = useState();

  // TODO: move
  const countries = {
    QAT: "Qatar",
    ECU: "Ecuador",
    FRA: "France",
    AUS: "Australia",
    DEU: "Germany",
    ESP: "Spain",
    URY: "Uruguay ",
    KOR: "Korea",
    ENG: "England",
    USA: "United States",
    ARG: "Argentina",
    MEX: "Mexico",
    HRV: "Croatia",
    IRN: "Iran",
    BRA: "Brazil",
    CHE: "Switzerland",
    SEN: "Senegal",
    NLD: "Netherlands",
    WAL: "Wales",
    CAN: "Canada",
    SAU: "Saudi Arabia",
    POL: "Poland",
    TUN: "Tunisia",
    DEN: "Denmark",
    JPN: "Japan",
    CRI: "Costa Rica",
    MAR: "Morocco",
    BEL: "Belgium",
    SER: "Serbia",
    CMR: "Cameroon",
    PRT: "Portugal",
    GHA: "Ghana"
  };


  useEffect(() => {
    const updateRanking = async () => {
      try {
        const response = await axios.get(backendUrl + "ranking/country");
        setRanking(response.data.results);
        console.log(response);
      } catch (error) {
        console.error(error);
      }
    };
    updateRanking();
  }, [backendUrl]);

  return (
    <>
      <div className="ranking-title">
        <div className="back">
          <i className="fa fa-arrow-left"></i>
          <Link to="/">All Rankings</Link>
        </div>
        <h1>Earth Cup Ranking</h1>
      </div>
        <div className="position-table">
          <table className="ranking-countries">
              <tr>
                <th>#</th>
                <th>Country</th>
                <th class="number">Users</th>
                <th class="number">Score</th>
              </tr>
              {ranking && ranking.length > 0 && ranking.map((country, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>
                    {countries[country.country] ? (
                      <>
                        <img src={"/images/countries/" + country.country + ".png"} alt="{countries[country.country]}" title="{countries[country.country]}" height="50" />
                        <span class="country-name">{countries[country.country]}</span>
                      </>
                    ) : (
                      <>{country.country}</>
                    )}
                  </td>
                  <td class="number"><Link to={"/countries/" + country.country}>{country.users} <i class="fa fa-trophy"></i></Link></td>
                  <td class="number">{country.base_score}</td>
                </tr>
              ))}
        </table>
      </div>
    </>
  );
}

export default RankingCountries;
