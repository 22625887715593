import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";

function RankingByMatchesWon({backendUrl}) {

  const selectedGroup = useParams().group;

  const [ranking, setRanking] = useState();

  let toDate = new Date().toISOString().slice(0, 10);

  let lastMondayDate = new Date();
  lastMondayDate.setDate(lastMondayDate.getDate() - (lastMondayDate.getDay() + 6) % 7);

  let fromDate = lastMondayDate.toISOString().slice(0, 10);

  if (selectedGroup === "gdex") {
    fromDate = "2022-07-08";
    toDate = "2022-07-10";
  }

  useEffect(() => {
    const updateRanking = async () => {
      try {
        const response = await axios.get(backendUrl + "ranking/total_won/desc", { params: { limit: 50, group: selectedGroup, from: fromDate, to: toDate } });
        setRanking(response.data.results);
      } catch (error) {
        console.error(error);
      }
    };
    updateRanking();
  }, [backendUrl, selectedGroup, fromDate, toDate]);

  return (
    <>
      <div className="ranking-title">
        <div className="back">
          <i className="fa fa-arrow-left"></i>
          <Link to="/">All Rankings</Link>
        </div>
        {(selectedGroup === "gdex") && (
          <div style={{ margin: "0 auto", width: 200 }}>
            <img src="/images/logo_gdex.png" alt="Logo gDEX" width="200" />
          </div>
        )}
        <h1>
          Ranking by Won Matches
          {(selectedGroup === "gdex") && (
            <span style={{ fontFamily: "none", fontWeight: "bold" }}> - gDEX Tournament</span>
          )}
        </h1>
        {(selectedGroup === "gdex") ? (
          <>
            <div className="subtitle">
              Learn more about this special Fulbo - gDEX tournament <a style={{ color: "white", fontWeight: "bold" }} target="_blank" href="https://medium.com/fulbo-galaxy/fulbo-galaxy-x-gdex-metaverse-tournament-34bc76695c19">HERE</a>!
            </div>
            <div className="subtitle">
              Not appearing in ranking? New users will appear after a couple of hours after the first match is played
            </div>
          </>
        ) : (
          <div className="subtitle">
            Not appearing in ranking? Please add your twitter account to participate
          </div>
        )}
      </div>
      <div className="ranking-data">
        <h2>From: <span>{fromDate}</span></h2>
        <h2>To: <span>{toDate}</span></h2>
      </div>
        <div className="position-table">
          <table>
              <tr>
                <th>#</th>
                <th>Twitter</th>
                <th>Team</th>
                <th>Total Won</th>
              </tr>
              {ranking && ranking.length > 0 && ranking.map((user, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{user.twitter}</td>
                  <td>{user.user}</td>
                  <td>{user.total_won}</td>
                </tr>
              ))}
        </table>
      </div>
    </>
  );
}

export default RankingByMatchesWon;
