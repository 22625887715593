import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";

function GlobalRankingByScore({backendUrl}) {

  const [ranking, setRanking] = useState();

  useEffect(() => {
    const updateRanking = async () => {
      try {
        const response = await axios.get(backendUrl + "ranking/max_score/desc", { params: { limit: 100, from: "2021-01-01" }});
        setRanking(response.data.results);
        console.log(response);
      } catch (error) {
        console.error(error);
      }
    };
    updateRanking();
  }, [backendUrl]);

  return (
    <>
      <div className="ranking-title">
        <div className="back">
          <i className="fa fa-arrow-left"></i>
          <Link to="/">All Rankings</Link>
        </div>
        <h1>Global Ranking by Score</h1>
        <div className="subtitle">
          Not appearing in ranking? Please add your twitter account to participate
        </div>
      </div>
      <div className="position-table">
        <table>
          <tr>
            <th>#</th>
            <th>Twitter</th>
            <th>Team</th>
            <th>Score</th>
            <th>Result</th>
            <th>Total Matches</th>
          </tr>
          {ranking && ranking.length > 0 && ranking.map((user, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>{user.twitter}</td>
              <td>{user.user}</td>
              <td>{user.max_score}</td>
              <td>
                {parseInt(user.max_full_score.slice(user.max_full_score.length-4, user.max_full_score.length-2))} - {parseInt(user.max_full_score.slice(user.max_full_score.length-2, user.max_full_score.length))}
              </td>
              <td>{user.total_matches}</td>
            </tr>
          ))}
        </table>
      </div>
    </>
  );
}

export default GlobalRankingByScore;
