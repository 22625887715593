import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Select, Table } from 'antd';
import axios from "axios";

const { Option } = Select;

function RankingByStadium({backendUrl}) {

  const [ranking, setRanking] = useState();
  const [loading, setLoading] = useState(false);

  const stadiums = ["Fulbo Stadium", "El Barrio Futbol Club", "Maximum Security FC"]; //, "Río de Jamaica Arena", "Berlin Wall Cup 89"];

  const columns = [
    {
      title: '#',
      dataIndex: 'index',
      sorter: false,
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: 'Team',
      dataIndex: 'user',
      sorter: false,
    },
    {
      title: 'Twitter',
      dataIndex: 'twitter',
      sorter: false,
    },
    {
      title: 'Result',
      dataIndex: 'max_full_score',
      render: (text, record, index) =>
        parseInt(text.slice(text.length-4, text.length-2)) + " - " + parseInt(text.slice(text.length-2, text.length)),
    },
    {
      title: 'Score',
      dataIndex: 'max_score',
      sorter: false,
    },
  ];

  const stadium = useParams().stadium;
  const level = useParams().level;
  let navigate = useNavigate();

  useEffect(() => {
    const updateRanking = async () => {
      try {
        setLoading(true);
        const response = await axios.get(backendUrl + "ranking/max_score/desc", { params: { level: level, stadium: stadium, from: "2021-01-01" }});
        console.log(response);
        setRanking(response.data.results);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    updateRanking();
  }, [backendUrl, level, stadium]);

  const handleChangeStadium = (value) => {
    navigate("/rankingByStadium/" + value + "/" + level);
  };

  return (
    <>
      <div className="ranking-title">
        <div className="back">
          <i className="fa fa-arrow-left"></i>
          <Link to="/">All Rankings</Link>
        </div>
        <h1>Ranking</h1>
      </div>
      <div className="ranking-data">
        <h2>
          Stadium:
          <Select value={stadiums[stadium]} style={{ width: 250 }} onChange={handleChangeStadium}>
            {stadiums.map((stadiumName, stadiumIndex) => <Option value={stadiumIndex}>{stadiumName}</Option>)}
          </Select>
        </h2>
        <h2>Level: {level}</h2>
      </div>
      <div className="position-table">
        <Table
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={ranking}
          loading={loading}
          pagination={false}
          sortDirections={['ascend', 'descend', 'ascend']}
        />
      </div>
    </>
  );
}

export default RankingByStadium;
